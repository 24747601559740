<template>
    <ContentField>
        404 not found
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'

export default {
    components: {
        ContentField
    }
} 

</script>


<style scoped>
</style>

